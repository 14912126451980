import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const Header_TradingVolume = () => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [search, setSearch] = useState("");
  const navigator = useNavigate();

  const handleSubmit = (e) => {
    navigator("/search/" + search);
    e.preventDefault();
  };

  const handleClickScroll = () => {
    setTimeout(() => {
      const element = document.getElementById("collection");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <>
      <header className={styles.header}>
        <div className={cn("container", styles.container)}>
          <div className={styles.logo}>ROALAND</div>
          <div className={styles.divider} />
          <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
            <nav className={styles.nav}>
              {/* <Link className={styles.link} to="/foundationwallet">
                <span className={styles.linkText}>Roa 카르도 지갑 현황</span>
              </Link> */}
              <Link className={styles.link} to="/">
                <span className={styles.linkText}>Roa 빗썸 거래량 현황</span>
              </Link>
            </nav>
          </div>
        </div>
      </header>
      <div className={styles.space} />
    </>
  );
};
