import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import cn from "classnames";
import styles from "./TradingVolume.module.scss";
import { Header } from "../header/HeaderCardo";
import { HeaderBithumb } from "../header/HeaderBithumb";
import { Header_TradingVolume } from "../header/Header_TradingVolume";

/**
 * @빗썸주소
 * 지갑1, HWAZftMPQKDjhCpa4n1ZC1v7WdbHZsoXBecie4qxtKqX
 * 지갑2, 6MvL8tepoVrFhtK4M7ZtjkmLrMi5MsKmq2hQNG1KspV2
 */

function TradingVolume() {
  // BTC 가격
  const [btcPrice, setBtcPrice] = useState(0);
  // 로아 3일 총 입금량
  const [roaTotal3days, setRoaTotal3days] = useState(0);
  // 현재가
  const [closingPrice, setClosingPrice] = useState(0);
  // 거래량 00시~현재
  const [unitsTraded, setUnitsTraded] = useState(0);
  // 최근 24시간 거래량 units_traded_24H
  const [unit24H, SetUnit24H] = useState(0);
  // 카운트
  const [count, setCount] = useState(15);
  // spl transfers (지갑1)
  const [splTrans, setSplTrans] = useState(0);
  // spl transfers2 (지갑2)
  const [splTrans2, setSplTrans2] = useState(0);

  const [password, setPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    // Replace 'your_password' with the actual correct password
    const correctPassword = "2050";

    if (password === correctPassword) {
      setIsPasswordCorrect(true);
    } else {
      alert("Incorrect password. Please try again.");
      setPassword("");
    }
  };
  const handleKeyPress = (e) => {
    // 엔터 키가 눌렸을 때
    if (e.key === "Enter") {
      handlePasswordSubmit();
    }
  };

  const btcPriceData = async () => {
    try {
      const response = await axios.get(
        "https://api.bithumb.com/public/ticker/BTC_KRW",
        {
          headers: {
            accept: "application/json",
          },
        }
      );

      // console.log("btc price :", response.data.data.closing_price);
      setBtcPrice(response.data.data.closing_price);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        "https://api.bithumb.com/public/ticker/ROA_KRW",
        {
          headers: {
            accept: "application/json",
          },
        }
      );

      // console.log("info1 :", response.data);
      setClosingPrice(response.data.data.closing_price);
      setUnitsTraded(response.data.data.units_traded);
      SetUnit24H(response.data.data.units_traded_24H);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 1) {
          return 15;
        } else {
          return prevCount - 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  const spltransfers = async () => {
    axios
      .get("https://pro-api.solscan.io/v1.0/account/splTransfers", {
        params: {
          account: "HWAZftMPQKDjhCpa4n1ZC1v7WdbHZsoXBecie4qxtKqX",
          limit: 100,
          offset: 0,
        },
        headers: {
          Accept: "application/json",
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODg2MjIzNjMwMDQsImVtYWlsIjoiYWhuZHkzOUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODg2MjIzNjN9.aldsVVn3WEYqPDrM-jO-4wd-QP_dm9BhOjqB9IccEwU",
        },
      })
      .then((response) => {
        // 요청 성공 시 데이터 처리
        // console.log("spltransfers1 :", response.data.data);
        setSplTrans(response.data.data);
        // console.log("blocktime :", response.data.data[0].blockTime);
      })
      .catch((error) => {
        // 요청 실패 시 에러 처리
        console.error(error);
      });
  };

  const spltransfers2 = async () => {
    axios
      .get("https://pro-api.solscan.io/v1.0/account/splTransfers", {
        params: {
          account: "6MvL8tepoVrFhtK4M7ZtjkmLrMi5MsKmq2hQNG1KspV2",
          limit: 100,
          offset: 0,
        },
        headers: {
          Accept: "application/json",
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODg2MjIzNjMwMDQsImVtYWlsIjoiYWhuZHkzOUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODg2MjIzNjN9.aldsVVn3WEYqPDrM-jO-4wd-QP_dm9BhOjqB9IccEwU",
        },
      })
      .then((response) => {
        // 요청 성공 시 데이터 처리
        // console.log("spltransfers2 :", response.data.data);
        setSplTrans2(response.data.data);
        // console.log("blocktime :", response.data.data[0].blockTime);
      })
      .catch((error) => {
        // 요청 실패 시 에러 처리
        console.error(error);
      });
  };

  // const url =
  //   "https://pro-api.solscan.io/v1.0/account/transactions?account=HWAZftMPQKDjhCpa4n1ZC1v7WdbHZsoXBecie4qxtKqX&limit=10";

  // axios
  //   .get(url)
  //   .then((response) => {
  //     console.log("transactions:", response.data);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });

  const transactions = async () => {
    axios
      .get("https://pro-api.solscan.io/v1.0/account/transactions", {
        params: {
          account: "HWAZftMPQKDjhCpa4n1ZC1v7WdbHZsoXBecie4qxtKqX",
          limit: 10,
        },
        headers: {
          Accept: "application/json",
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODg2MjIzNjMwMDQsImVtYWlsIjoiYWhuZHkzOUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODg2MjIzNjN9.aldsVVn3WEYqPDrM-jO-4wd-QP_dm9BhOjqB9IccEwU",
        },
      })
      .then((response) => {
        // 요청 성공 시 데이터 처리
        // console.log("tranjactions :", response.data);
      })
      .catch((error) => {
        // 요청 실패 시 에러 처리
        console.error(error);
      });
  };

  useEffect(() => {
    fetchData1();
    spltransfers();
    spltransfers2();
    transactions();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData1();
      spltransfers();
      spltransfers2();
      transactions();
    }, 15000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // 현재 시간
  const currentDate = new Date();

  // 현재날짜에서 3일전
  const twoDaysAgo = new Date(currentDate);
  twoDaysAgo.setDate(currentDate.getDate() - 2);

  // 현재날짜에서 6일전
  const sixDaysAgo = new Date(currentDate);
  sixDaysAgo.setDate(currentDate.getDate() - 5);

  // Set the time to 00:00:00
  twoDaysAgo.setHours(0, 0, 0, 0);
  sixDaysAgo.setHours(0, 0, 0, 0);
  // Get the Unix time (in milliseconds) for the date
  const unixTimeTwoDays = twoDaysAgo.getTime();
  const unixTimeSixDays = sixDaysAgo.getTime();

  // Convert to seconds (Unix time is usually represented in seconds)
  const beforeTwoDaysAgo = Math.floor(unixTimeTwoDays / 1000);
  const beforeSixDaysAgo = Math.floor(unixTimeSixDays / 1000);

  // console.log("beforTwoDaysAgo :", beforeTwoDaysAgo);
  // console.log("beforeSixDaysAgo :", beforeSixDaysAgo);

  let splArrayData1 = [];
  let splArrayData2 = [];

  splArrayData1 = [...Array.from(splTrans)];
  splArrayData2 = [...Array.from(splTrans2)];

  // before 3days address_1
  let filteredDataThreeA = splArrayData1.filter(
    (item) =>
      item.blockTime >= beforeTwoDaysAgo &&
      item.changeAmount > 0 &&
      item.symbol === "ROA"
  );
  let totalChangeAmountA = filteredDataThreeA.reduce(
    (sum, item) => sum + item.changeAmount,
    0
  );

  // before 3days address_2
  let filteredDataThreeB = splArrayData2.filter(
    (item) =>
      item.blockTime >= beforeTwoDaysAgo &&
      item.changeAmount > 0 &&
      item.symbol === "ROA"
  );
  let totalChangeAmountB = filteredDataThreeB.reduce(
    (sum, item) => sum + item.changeAmount,
    0
  );

  // before 6days address_1
  let filteredDataSixA = splArrayData1.filter(
    (item) =>
      item.blockTime < beforeTwoDaysAgo &&
      item.blockTime >= beforeSixDaysAgo &&
      item.changeAmount > 0 &&
      item.symbol === "ROA"
  );
  let totalChangeAmountSixA = filteredDataSixA.reduce(
    (sum, item) => sum + item.changeAmount,
    0
  );

  // before 6days address_2
  let filteredDataSixB = splArrayData2.filter(
    (item) =>
      item.blockTime < beforeTwoDaysAgo &&
      item.blockTime >= beforeSixDaysAgo &&
      item.changeAmount > 0 &&
      item.symbol === "ROA"
  );
  let totalChangeAmountSixB = filteredDataSixB.reduce(
    (sum, item) => sum + item.changeAmount,
    0
  );

  // // 하루동안
  const today = new Date(currentDate);
  today.setDate(currentDate.getDate());

  // // Set the time to 00:00:00
  today.setHours(0, 0, 0, 0);

  const unixTimeToday = today.getTime();

  const midNight = Math.floor(unixTimeToday / 1000);

  const 지갑1오늘입금량필터 = splArrayData1?.filter((item) => {
    return (
      item.blockTime >= midNight &&
      item.changeAmount > 0 &&
      item.symbol === "ROA"
    );
  });

  const 지갑2오늘입금량필터 = splArrayData2?.filter((item) => {
    return (
      item?.blockTime >= midNight &&
      item?.changeAmount > 0 &&
      item?.symbol === "ROA"
    );
  });

  const 지갑1입금량총합 = 지갑1오늘입금량필터?.reduce(
    (sum, item) => sum + item.changeAmount,
    0
  );

  const 지갑2입금량총합 = 지갑2오늘입금량필터?.reduce(
    (sum, item) => sum + item.changeAmount,
    0
  );

  const 지갑2개입금량총합 =
    Number((지갑1입금량총합 * 0.000000001).toFixed(2)) +
    Number((지갑2입금량총합 * 0.000000001).toFixed(2));

  // console.log("totalChangeAmountA :", totalChangeAmountA);

  let threeD =
    Number((totalChangeAmountA * 0.000000001).toFixed(2)) +
    Number((totalChangeAmountB * 0.000000001).toFixed(2));

  let sixD =
    Number((totalChangeAmountSixA * 0.000000001).toFixed(2)) +
    Number((totalChangeAmountSixB * 0.000000001).toFixed(2));

  // roa 총 입금량 3일치
  const totalDeposit3Days = (
    Number((totalChangeAmountA * 0.000000001).toFixed(2)) +
    Number((totalChangeAmountB * 0.000000001).toFixed(2))
  ).toLocaleString("to-KR");
  // roa 총 입금량 6일전~3일전
  const totalDeposit6Days = (
    Number((totalChangeAmountSixA * 0.000000001).toFixed(2)) +
    Number((totalChangeAmountSixB * 0.000000001).toFixed(2))
  ).toLocaleString("to-KR");
  // recent 3일 | recent 6일 | 비교
  const recentCompare = (((threeD - sixD) / sixD) * 100).toFixed(2);

  // 하루 입금 가능량
  const todayDepositLimit = (sixD / 3)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 하루 입금량
  const todayTotalDeposit = 지갑2개입금량총합
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 총 거래량(0시부터~현재시간까지)
  const totalTrade = Number(unitsTraded)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // 총 거래량의 10%
  const totalTrade10per = (Number(unitsTraded) * 0.1)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 총 거래량의 15%
  const totalTrade15per = (Number(unitsTraded) * 0.15)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // roa 현재 가격
  const roaPrice = String(Number(Number(closingPrice).toFixed(2))).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // 최근 24시간 거래량 : units_traded_24H
  const unitTrade24 = String(Number(unit24H).toFixed(2)).replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatDateTime = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = dateTime.toLocaleString("default", { month: "long" });
    const day = dateTime.getDate();
    const dayOfWeek = dateTime.toLocaleString("default", { weekday: "long" });
    const ampm = dateTime.getHours() >= 12 ? "오후" : "오전";
    const hours = dateTime.getHours() % 12 || 12;
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();

    return `${year}년 ${month} ${day}일 ${dayOfWeek} ${ampm} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      <Header_TradingVolume />
      <div>
        {isPasswordCorrect ? (
          // 비밀번호가 올바른 경우 표시되는 콘텐츠
          <div>
            {/* 여기에 보호된 콘텐츠를 추가하세요 */}
            <div className={cn(styles.section)}>
              <span className={cn(styles.refresh)}>
                {count}초 뒤에 새로고침 됩니다.
              </span>
              <div>
                {/* <h1>현재 시간 및 날짜</h1>
                <p>{formatDateTime(currentDateTime)}</p> */}
              </div>
            </div>

            <div className={cn(styles.width)}>
              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  이전 4일~6일
                  <div>ROA 총 입금량</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{totalDeposit6Days}</div>
              </a>

              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  최근3일
                  <div>ROA 총 입금량</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{totalDeposit3Days}</div>
              </a>

              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  비교
                  <div>직전 6일 -{">"} 3일 (%)</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{recentCompare}%</div>
              </a>

              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  하루 입금량
                  <div>※금일 자정 기준</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{todayTotalDeposit}</div>
              </a>

              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  총 거래량
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{totalTrade}</div>
              </a>

              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  총 거래량의 15%
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{totalTrade15per}</div>
              </a>

              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  최근 24시간 거래량
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{unitTrade24}</div>
              </a>
              <a className={cn(styles.card)}>
                <div
                  className={cn(styles.cardTitle)}
                  style={{ marginTop: 16, marginBottom: 14 }}
                >
                  roa 원화 현재 가격
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className={cn(styles.cardDivider)}></div>
                </div>
                <div className={cn(styles.cardGoal)}>{roaPrice}</div>
              </a>

              {/* <table className="top-table">
        <thead>
          <tr>
            <th>
              ROA 총 입금량
              <div>4일~6일</div>
            </th>
            <th>
              최근3일
              <div>ROA 총 입금량</div>
            </th>
            <th>
              비교
              <div>직전 6일 -{">"} 3일 (%)</div>
            </th>
            <th>하루 입금 가능량</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalDeposit6Days}</td>
            <td>{totalDeposit3Days}</td>
            <td>{recentCompare}%</td>
            <td>{todayDepositLimit}</td>
          </tr>
        </tbody>
      </table>

      <table className="bottom-table">
        <thead>
          <tr>
            <th>총 거래량</th>
            <th>총 거래량의 15%</th>
            <th>최근 24시간 거래량</th>
            <th>roa 원화 현재 가격</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{totalTrade}</td>
            <td>{totalTrade15per}</td>
            <td>{unitTrade24}</td>
            <td>{roaPrice}</td>
          </tr>
        </tbody>
      </table> */}
            </div>
          </div>
        ) : (
          // 비밀번호 입력 폼
          <div>
            <h1>Enter Password to Access</h1>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onKeyPress={handleKeyPress}
            />
            <button onClick={handlePasswordSubmit}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default TradingVolume;
