import { useEffect, useMemo, useState } from "react";
import axios from "axios";

import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./FoundationWallet.module.scss";
import { Header, HeaderCardo } from "../header/HeaderCardo";
import { Header_FoundationWallet } from "../header/Header_FoundationWallet";

/**
 * @빗썸주소
 * 지갑1, HWAZftMPQKDjhCpa4n1ZC1v7WdbHZsoXBecie4qxtKqX
 * 지갑2, 6MvL8tepoVrFhtK4M7ZtjkmLrMi5MsKmq2hQNG1KspV2
 */

function FoundationWallet() {
  // 카운트
  const [count, setCount] = useState(10);

  // 조회버튼
  const [list, setList] = useState(false);

  // 3일전 시간
  const [splTransData, setSplTransData] = useState({});
  const [tokenData, setTokenData] = useState({});

  // 지갑주소
  const ECOSYSTEM_ADDRESS = "9MmwJc5gCu7z2iKdf7DJTFawQN4HRHXJDyBB5LExsBKt";
  const FOUNDER_ADDRESS = "AyZqvwsoYZcEkdjCDGRwcjK1gAiDqqkVm4ZspExwg6KJ";
  const PARTNER_ADDRESS = "CAWUKydocbqRMKmdCq3XejXE7ruHQc9xfREPG2VW5aKD";
  const EVENT_ADDRESS = "4uJHJDhpETzZF5o6X1vnpgP6RKqsbwqLocabcA2DX7mo";
  const ADVISOR_ADDRESS = "6Un2VtzNNick9AeVgxoaDijotR1LFw1A7ipg93Zs2L35";
  const MARKETING_ADDRESS = "BYtmgqzZyMSDRhJfVhKMVQ2sGpAJELKyJ5c5Aavr7TPm";
  const INVESTOR_ADDRESS = "EHVeD5SgRJWQoKW997rKFZNm1QiEykZs3e1tVbPe7oMf";
  const CARDO_1_ADDRESS = "CX8tT7zDfqR6cbZi2bGYbfxMi7sokdhQtDL4x9CHXHGo";
  const CARDO_2_ADDRESS = "BD9hLDSwXrjR4TCdX4NxoPqHUvfU86riq7McpbUCHXqf";
  const BITHUMB_ADDRESS = "29eyu3ZUzTW3Dimt7ktTQEgDsAfdfLECATmsaMs2a9Pn";

  // ECO-SYSTEM
  const [dataEcosystem, setDataEcosystem] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // FOUNDER
  const [dataFounder, setDataFounder] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // PARTNER
  const [dataPartner, setDataPartner] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // EVENT
  const [dataEvent, setDataEvent] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // ADVISOR
  const [dataAdvisor, setDataAdvisor] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // MARKETING
  const [dataMarketing, setDataMarketing] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // INVESTOR
  const [dataInvestor, setDataInvestor] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // CARDO-1
  const [dataCardo_1, setDataCardo_1] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });

  // CARDO-2
  const [dataCardo_2, setDataCardo_2] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });
  // BITHUMB
  const [dataBithumb, setDataBithumb] = useState({
    change: 0,
    deposit: 0,
    withdrawal: 0,
    tokenAmount: 0,
  });

  const [showPreview, setShowPreview] = useState({
    ECOSYSTEM: false,
    FOUNDER: false,
    PARTNER: false,
    EVENT: false,
    ADVISOR: false,
    MARKETING: false,
    INVESTOR: false,
    CARDOTOUS1: false,
    CARDOTOUS2: false,
    BITHUMB: false,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  // 조회기간 설정하기
  const [period, setPeriod] = useState("");
  const [toTime, setToTime] = useState("");
  const [fromTime, setFromTime] = useState("");
  // 날짜입력받기
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const currentTimeInMilliseconds = new Date().getTime();

  // 빗썸api키
  const options = {
    method: "POST",
    url: "https://api.bithumb.com/info/ticker",
    headers: {
      accept: "application/json",
      "content-type": "application/x-www-form-urlencoded",
      "Api-Key": "5bc8e791b851564c14744c81485cc385",
      "Api-Nonce": currentTimeInMilliseconds,
      "Api-Sign": "상세 가이드 참고",
    },
  };

  axios
    .request(options)
    .then(function (response) {
      console.log("빗썸 response :", response.data);
    })
    .catch(function (error) {
      console.error(error);
    });

  const b_api1 = async () => {
    try {
      const response = await axios.post("https://api.bithumb.com/info/ticker", {
        headers: {
          accept: "application/json",
          "content-type": "application/x-www-form-urlencoded",
          "Api-Key": "5bc8e791b851564c14744c81485cc385",
          "Api-Nonce": currentTimeInMilliseconds,
          "Api-Sign": "상세 가이드 참고",
        },
      });

      console.log("빗썸 response :", response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    // Replace 'your_password' with the actual correct password
    const correctPassword = "1030";

    if (password === correctPassword) {
      setIsPasswordCorrect(true);
    } else {
      alert("Incorrect password. Please try again.");
      setPassword("");
    }
  };

  const handleKeyPress = (e) => {
    // 엔터 키가 눌렸을 때
    if (e.key === "Enter") {
      handlePasswordSubmit();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 1) {
          // Additional actions to perform when countdown reaches 1 second
          return 10;
        } else {
          return prevCount - 1;
        }
      });
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [count]);

  // Memoize the updated count to prevent unnecessary re-rendering
  const memoizedCount = useMemo(() => count, [count]);

  const fetchRoaToken = async (accountAddress) => {
    try {
      const response = await axios.get(
        "https://pro-api.solscan.io/v1.0/account/tokens",
        {
          params: {
            account: accountAddress,
            limit: 50,
            offset: 0,
          },
          headers: {
            Accept: "application/json",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODg2MjIzNjMwMDQsImVtYWlsIjoiYWhuZHkzOUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODg2MjIzNjN9.aldsVVn3WEYqPDrM-jO-4wd-QP_dm9BhOjqB9IccEwU", // Replace with your actual API token
          },
        }
      );

      // console.log("token----- :", response.data);
      // Save the data for the current account
      setTokenData((prevData) => ({
        ...prevData,
        [accountAddress]: response.data,
      }));

      // Return the data if needed
      return response.data.data;
    } catch (error) {
      // Error handling when request fails
      console.error(error);
      return null; // Return null or handle the error as needed
    }
  };

  const fetchSplTransfers = async (accountAddress) => {
    try {
      const response = await axios.get(
        `https://pro-api.solscan.io/v1.0/account/splTransfers`,
        {
          params: {
            account: accountAddress,
            limit: 50,
            offset: 0,
            // totime: toTime,
            // fromtime: fromTime,
          },
          headers: {
            Accept: "application/json",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODg2MjIzNjMwMDQsImVtYWlsIjoiYWhuZHkzOUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODg2MjIzNjN9.aldsVVn3WEYqPDrM-jO-4wd-QP_dm9BhOjqB9IccEwU", // Replace with your actual API token
          },
        }
      );

      // Save the data for the current account
      setSplTransData((prevData) => ({
        ...prevData,
        [accountAddress]: response.data.data,
      }));

      setIsLoading(false);
      // Return the data if needed
      return response.data.data;
    } catch (error) {
      // Error handling when request fails
      console.error(error);
      setIsLoading(false);
      return null; // Return null or handle the error as needed
    }
  };
  // splTransfer 기간조회
  const fetchSplTransfersPeriod = async (
    accountAddress,
    startTime,
    endTime
  ) => {
    try {
      const response = await axios.get(
        `https://pro-api.solscan.io/v1.0/account/splTransfers`,
        {
          params: {
            account: accountAddress,
            limit: 100,
            offset: 0,
            // fromTime: startTime,
            // toTime: endTime,
            fromTime: startTime,
            toTime: endTime,
          },
          headers: {
            Accept: "application/json",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjcmVhdGVkQXQiOjE2ODg2MjIzNjMwMDQsImVtYWlsIjoiYWhuZHkzOUBnbWFpbC5jb20iLCJhY3Rpb24iOiJ0b2tlbi1hcGkiLCJpYXQiOjE2ODg2MjIzNjN9.aldsVVn3WEYqPDrM-jO-4wd-QP_dm9BhOjqB9IccEwU", // Replace with your actual API token
          },
        }
      );

      // Save the data for the current account
      setSplTransData((prevData) => ({
        ...prevData,
        [accountAddress]: response.data.data,
      }));

      // console.log("기간조회fromTIme:", startTime);
      // console.log("기간조회toTime :", endTime);
      // console.log("기간조회spl data :", response.data.data);
      setIsLoading(false);
      // Return the data if needed
      return response.data.data;
    } catch (error) {
      // Error handling when request fails
      console.error(error);
      setIsLoading(false);
      return null; // Return null or handle the error as needed
    }
  };

  const startString = new Date(startDate).getTime();

  const unixSecondStartDate = Math.floor(
    new Date(startString).getTime() / 1000
  );

  const endString = String(endDate);
  const unixSecondEndDate = Math.floor(new Date(endString).getTime() / 1000);

  const 기간설정 = () => {
    setFromTime(unixSecondStartDate);
    setToTime(unixSecondEndDate);
    기간설정새로고침(unixSecondStartDate, unixSecondEndDate);
  };

  const 기간설정초기화 = () => {
    setFromTime("");
    setToTime("");

    setStartDate("");
    setEndDate("");
  };

  // // 현재 시간
  // const currentDate = new Date();

  // // 하루동안
  // const today = new Date(currentDate);
  // today.setDate(currentDate.getDate());

  // // Set the time to 00:00:00
  // today.setHours(0, 0, 0, 0);

  // Get the Unix time (in milliseconds) for the date
  // 현재 시간의 Unix 시간 가져오기
  const currentUnixTime = Math.floor(new Date().getTime() / 1000);

  // Convert to seconds (Unix time is usually represented in seconds)
  // const midNight = Math.floor(unixTimeToday / 1000);
  const twentyFourHoursAgoUnixTime = currentUnixTime - 24 * 60 * 60;

  const filterDataTransfer = async (accountAddress) => {
    if (splTransData[accountAddress]) {
      let splArrayData = [...Array.from(splTransData[accountAddress])];

      // console.log("splArrayData : ", splArrayData);

      // 입금량
      const 입금량필터 = splArrayData?.filter((item) => {
        return (
          item.blockTime >= twentyFourHoursAgoUnixTime &&
          item.changeAmount > 0 &&
          item.symbol === "ROA"
        );
      });
      // 출금량
      const 출금량필터 = splArrayData?.filter((item) => {
        return (
          item?.blockTime >= twentyFourHoursAgoUnixTime &&
          item?.changeAmount < 0 &&
          item?.symbol === "ROA"
        );
      });

      const 입금량총합데시멀 = 입금량필터?.reduce(
        (sum, item) => sum + item.changeAmount,
        0
      );

      const 출금량총합데시멀 = 출금량필터?.reduce(
        (sum, item) => sum + item.changeAmount,
        0
      );
      const 입금량총합 = Number((입금량총합데시멀 * 0.000000001).toFixed(2));

      const 출금량총합 = Number((출금량총합데시멀 * 0.000000001).toFixed(2));

      // 토큰명이 ROA 인 데이터 filter
      const filteredROAData = tokenData[accountAddress]?.filter(
        (item) => item && item?.tokenSymbol && item?.tokenSymbol === "ROA"
      );

      let uiAmountString;
      // Check if there's at least one item in the filteredROAData array
      if (filteredROAData && filteredROAData.length > 0) {
        // Access the Number(uiAmountString) of the 0th item
        uiAmountString = filteredROAData[0]?.tokenAmount?.uiAmountString
          ? Number(filteredROAData[0]?.tokenAmount?.uiAmountString)
          : 0;
      } else {
        uiAmountString = 0;
        console.log("No data with tokenSymbol 'ROA' found.");
      }

      if (accountAddress === ECOSYSTEM_ADDRESS) {
        setDataEcosystem({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
          tokenRoa: filteredROAData ? true : false,
        });
      } else if (accountAddress === FOUNDER_ADDRESS) {
        setDataFounder({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === PARTNER_ADDRESS) {
        setDataPartner({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === EVENT_ADDRESS) {
        setDataEvent({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === ADVISOR_ADDRESS) {
        setDataAdvisor({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === MARKETING_ADDRESS) {
        setDataMarketing({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === INVESTOR_ADDRESS) {
        setDataInvestor({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === CARDO_1_ADDRESS) {
        setDataCardo_1({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === CARDO_2_ADDRESS) {
        setDataCardo_2({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === BITHUMB_ADDRESS) {
        setDataBithumb({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      }
    }
  };

  const filterDataTransferPeriod = async (
    accountAddress,
    startTime,
    endTime
  ) => {
    if (splTransData[accountAddress]) {
      let splArrayData = [...Array.from(splTransData[accountAddress])];

      // console.log("splArrayData : ", splArrayData);

      // 입금량
      const 입금량필터 = splArrayData?.filter((item) => {
        return (
          item.blockTime >= startTime &&
          item.blockTime < endTime &&
          item.changeAmount > 0 &&
          item.symbol === "ROA"
        );
      });
      // 출금량
      const 출금량필터 = splArrayData?.filter((item) => {
        return (
          item.blockTime >= startTime &&
          item.blockTime < endTime &&
          item?.changeAmount < 0 &&
          item?.symbol === "ROA"
        );
      });

      const 입금량총합데시멀 = 입금량필터?.reduce(
        (sum, item) => sum + item.changeAmount,
        0
      );

      const 출금량총합데시멀 = 출금량필터?.reduce(
        (sum, item) => sum + item.changeAmount,
        0
      );
      const 입금량총합 = Number((입금량총합데시멀 * 0.000000001).toFixed(2));

      const 출금량총합 = Number((출금량총합데시멀 * 0.000000001).toFixed(2));

      // 토큰명이 ROA 인 데이터 filter
      const filteredROAData = tokenData[accountAddress]?.filter(
        (item) => item && item?.tokenSymbol && item?.tokenSymbol === "ROA"
      );

      let uiAmountString;
      // Check if there's at least one item in the filteredROAData array
      if (filteredROAData && filteredROAData.length > 0) {
        // Access the Number(uiAmountString) of the 0th item
        uiAmountString = filteredROAData[0]?.tokenAmount?.uiAmountString
          ? Number(filteredROAData[0]?.tokenAmount?.uiAmountString)
          : 0;
      } else {
        uiAmountString = 0;
        console.log("No data with tokenSymbol 'ROA' found.");
      }

      if (accountAddress === ECOSYSTEM_ADDRESS) {
        setDataEcosystem({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
          tokenRoa: filteredROAData ? true : false,
        });
      } else if (accountAddress === FOUNDER_ADDRESS) {
        setDataFounder({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === PARTNER_ADDRESS) {
        setDataPartner({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === EVENT_ADDRESS) {
        setDataEvent({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === ADVISOR_ADDRESS) {
        setDataAdvisor({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === MARKETING_ADDRESS) {
        setDataMarketing({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === INVESTOR_ADDRESS) {
        setDataInvestor({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === CARDO_1_ADDRESS) {
        setDataCardo_1({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === CARDO_2_ADDRESS) {
        setDataCardo_2({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      } else if (accountAddress === BITHUMB_ADDRESS) {
        setDataBithumb({
          change: 입금량총합 + 출금량총합,
          deposit: 입금량총합,
          withdrawal: 출금량총합,
          tokenAmount: Number(uiAmountString)?.toLocaleString(),
        });
      }
    }
  };

  const fetchData = async (address) => {
    // console.log("splTr ", splTransData);
    try {
      await fetchRoaToken(address);
      await fetchSplTransfers(address);
      filterDataTransfer(address);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataPeriod = async (address, startDate, endDate) => {
    // console.log("splTr ", splTransData);
    setList(true);
    try {
      await fetchRoaToken(address);
      await fetchSplTransfersPeriod(address, startDate, endDate);
      filterDataTransferPeriod(address, startDate, endDate);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // // ECO-SYSTEM
  // function getEcosystemData() {

  // }
  function RefreshOnce() {
    fetchData(ECOSYSTEM_ADDRESS);
    fetchData(FOUNDER_ADDRESS);
    fetchData(PARTNER_ADDRESS);
    fetchData(EVENT_ADDRESS);
    fetchData(ADVISOR_ADDRESS);
    fetchData(MARKETING_ADDRESS);
    fetchData(INVESTOR_ADDRESS);
    fetchData(CARDO_1_ADDRESS);
    fetchData(CARDO_2_ADDRESS);
    fetchData(BITHUMB_ADDRESS);
  }

  function Refresh() {
    setList(true);
    fetchData(ECOSYSTEM_ADDRESS);
    fetchData(FOUNDER_ADDRESS);
    fetchData(PARTNER_ADDRESS);
    fetchData(EVENT_ADDRESS);
    fetchData(ADVISOR_ADDRESS);
    fetchData(MARKETING_ADDRESS);
    fetchData(INVESTOR_ADDRESS);
    fetchData(CARDO_1_ADDRESS);
    fetchData(CARDO_2_ADDRESS);
    fetchData(BITHUMB_ADDRESS);
  }

  function 기간설정새로고침(fromTime, toTime) {
    fetchDataPeriod(ECOSYSTEM_ADDRESS, fromTime, toTime);
    fetchDataPeriod(FOUNDER_ADDRESS, fromTime, toTime);
    fetchDataPeriod(PARTNER_ADDRESS, fromTime, toTime);
    fetchDataPeriod(EVENT_ADDRESS, fromTime, toTime);
    fetchDataPeriod(ADVISOR_ADDRESS, fromTime, toTime);
    fetchDataPeriod(MARKETING_ADDRESS, fromTime, toTime);
    fetchDataPeriod(INVESTOR_ADDRESS, fromTime, toTime);
    fetchDataPeriod(CARDO_1_ADDRESS, fromTime, toTime);
    fetchDataPeriod(CARDO_2_ADDRESS, fromTime, toTime);
    fetchDataPeriod(BITHUMB_ADDRESS, fromTime, toTime);
  }

  // useEffect(() => {
  //   // Set a timeout for 5 seconds (5000 milliseconds)
  //   const timeoutId = setTimeout(() => {
  //     Refresh();
  //   }, 3000);

  //   // Clean up the timeout to avoid memory leaks
  //   return () => clearTimeout(timeoutId);
  // }, []);

  useEffect(() => {
    RefreshOnce();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      Refresh();
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const openUrlInNewTab = (address) => {
    // 열고자 하는 URL
    const urlToOpen = `https://solscan.io/account/${address}#splTransfers`;

    // 새 탭에서 URL 열기
    window.open(urlToOpen, "_blank");
  };

  // const [currentDateTime, setCurrentDateTime] = useState(new Date());

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setCurrentDateTime(new Date());
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);

  const formatDateTime = (dateTime) => {
    const year = dateTime.getFullYear();
    const month = dateTime.toLocaleString("default", { month: "long" });
    const day = dateTime.getDate();
    const dayOfWeek = dateTime.toLocaleString("default", { weekday: "long" });
    const ampm = dateTime.getHours() >= 12 ? "오후" : "오전";
    const hours = dateTime.getHours() % 12 || 12;
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();

    return `${year}년 ${month} ${day}일 ${dayOfWeek} ${ampm} ${hours}:${minutes}:${seconds}`;
  };

  // const formattedTime = formatDateTime(currentDateTime);

  const DateInput = () => {
    return (
      <div>
        <label style={{ marginRight: 10 }} htmlFor="startDate">
          Start Date :
        </label>
        <input
          type="date"
          id="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <span style={{ marginRight: 20 }}></span>
        <label style={{ marginRight: 10 }} htmlFor="endDate">
          End Date :
        </label>
        <input
          type="date"
          id="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
    );
  };

  function CardComponent(props) {
    const isShowPreview = props.showPreview;
    return (
      <a className={cn(styles.card)}>
        <div
          className={cn(styles.cardTitle)}
          style={{ marginTop: 16, marginBottom: 14 }}
        >
          {props.타이틀}
          <div>
            {" "}
            {/* <button
              className={styles.refreshButton}
              onClick={() => fetchData(props.지갑주소)}
            >
              {splTransData[props.지갑주소]?.length > 0 ? (
                "데이터 다시 불러오기"
              ) : isLoading ? (
                "데이터 로딩중"
              ) : (
                <span style={{ color: "red" }}>조회된 데이터 없음</span>
              )}
            </button> */}
            {unixSecondStartDate && unixSecondEndDate ? (
              <button
                className={styles.refreshButton}
                onClick={() =>
                  fetchDataPeriod(
                    props.지갑주소,
                    unixSecondStartDate,
                    unixSecondEndDate
                  )
                }
              >
                {splTransData[props.지갑주소]?.length > 0 ? (
                  "데이터 다시 불러오기"
                ) : isLoading ? (
                  "데이터 로딩중"
                ) : (
                  <span style={{ color: "red" }}>조회된 데이터 없음</span>
                )}
              </button>
            ) : (
              <button
                className={styles.refreshButton}
                onClick={() => fetchData(props.지갑주소)}
              >
                {splTransData[props.지갑주소]?.length > 0 ? (
                  "데이터 다시 불러오기"
                ) : isLoading ? (
                  "데이터 로딩중"
                ) : (
                  <span style={{ color: "red" }}>조회된 데이터 없음</span>
                )}
              </button>
            )}
          </div>
          {isShowPreview ? (
            <>
              <button
                className={styles.previewButton}
                onClick={props.togglePreview}
              >
                닫기
              </button>
              <div style={{ fontSize: "12px" }}>{props.지갑주소}</div>
            </>
          ) : (
            <button
              className={styles.previewButton}
              onClick={props.togglePreview}
            >
              지갑주소보기
            </button>
          )}
          <div>
            {" "}
            <button
              className={styles.solscanButton}
              onClick={() => openUrlInNewTab(props.지갑주소)}
            >
              Solscan에서 보기
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <div className={cn(styles.cardDivider)}></div>
        </div>
        <div className={cn(styles.cardGoal)}>변동량 : {props.변동량}</div>
        <div className={cn(styles.cardGoal)}>입금 : {props.입금}</div>
        <div className={cn(styles.cardGoal)}>출금 : {props.출금}</div>
        <div>{props.유무}</div>
        {/* {props.출금 < 0 ? (
          <>
            <div className={cn(styles.cardGoal)}>
              출금 :
              <span className={cn(styles.widhdrawal_minus)}> {props.출금}</span>
            </div>
          </>
        ) : (
          <>
            <div className={cn(styles.cardGoal)}>
              출금 :
              <span className={cn(styles.widhdrawal_plus)}> {props.출금}</span>
            </div>
          </>
        )} */}
        <div className={cn(styles.cardGoal)}>현재 수량 : {props.현재수량}</div>
      </a>
    );
  }

  return (
    <div>
      <Header_FoundationWallet />

      <div>
        {isPasswordCorrect ? (
          // Content to display when the password is correct
          <div>
            {/* Add your protected content here */}
            <div className={cn(styles.section)}>
              <span className={cn(styles.refresh)}>
                {memoizedCount}분 뒤에 새로고침 됩니다.
              </span>
              <div>
                <button
                  className={styles.refreshButton}
                  style={{ color: "blue" }}
                  onClick={Refresh}
                >
                  최근 24시간 내역 조회
                </button>
              </div>
              <div>
                {/* <h1>현재 시간 및 날짜</h1> */}
                {/* <p>{formatDateTime(currentDateTime)}</p> */}
                {/* <p>{formattedTime}</p> */}
                <div>
                  <h3>
                    ※ 변동량의 기준은 최근 24시간 기준입니다.{" "}
                    <div>(설정된 기간이 없을 경우)</div>
                  </h3>
                </div>
              </div>
              <div>
                <DateInput />{" "}
                <button
                  className={styles.periodButton}
                  style={{ color: "hotpink" }}
                  onClick={기간설정}
                >
                  설정된 기간으로 조회
                </button>
                <span style={{ marginRight: 10 }}></span>
                <button
                  className={styles.periodButton}
                  style={{ color: "black" }}
                  onClick={기간설정초기화}
                >
                  기간설정 초기화
                </button>
              </div>
              <button onClick={b_api1}>빗섬</button>
            </div>

            {list && (
              <div className={cn(styles.width)}>
                {/* ECO_SYSTEM */}

                <CardComponent
                  타이틀="ECO-SYSTEM"
                  지갑주소={ECOSYSTEM_ADDRESS}
                  변동량={dataEcosystem.change ? dataEcosystem.change : 0}
                  입금={dataEcosystem.deposit ? dataEcosystem.deposit : 0}
                  출금={dataEcosystem.withdrawal ? dataEcosystem.withdrawal : 0}
                  현재수량={
                    dataEcosystem.tokenAmount ? dataEcosystem.tokenAmount : 0
                  }
                  // 유무={dataEcosystem.tokenRoa ? "true" : "false"}
                  showPreview={showPreview.ECOSYSTEM}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      ECOSYSTEM: !prev.ECOSYSTEM,
                    }))
                  }
                />
                {/* FOUNDER */}
                <CardComponent
                  타이틀="FOUNDER"
                  지갑주소={FOUNDER_ADDRESS}
                  변동량={dataFounder.change ? dataFounder.change : 0}
                  입금={dataFounder.deposit ? dataFounder.deposit : 0}
                  출금={dataFounder.withdrawal ? dataFounder.withdrawal : 0}
                  현재수량={
                    dataFounder.tokenAmount ? dataFounder.tokenAmount : 0
                  }
                  showPreview={showPreview.FOUNDER}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      FOUNDER: !prev.FOUNDER,
                    }))
                  }
                />
                {/* PARTNER */}
                <CardComponent
                  타이틀="PARTNER"
                  지갑주소={PARTNER_ADDRESS}
                  변동량={dataPartner.change ? dataPartner.change : 0}
                  입금={dataPartner.deposit ? dataPartner.deposit : 0}
                  출금={dataPartner.withdrawal ? dataPartner.withdrawal : 0}
                  현재수량={
                    dataPartner.tokenAmount ? dataPartner.tokenAmount : 0
                  }
                  showPreview={showPreview.PARTNER}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      PARTNER: !prev.PARTNER,
                    }))
                  }
                />
                {/* EVENT */}
                <CardComponent
                  타이틀="EVENT"
                  지갑주소={EVENT_ADDRESS}
                  변동량={dataEvent.change ? dataEvent.change : 0}
                  입금={dataEvent.deposit ? dataEvent.deposit : 0}
                  출금={dataEvent.withdrawal ? dataEvent.withdrawal : 0}
                  현재수량={dataEvent.tokenAmount ? dataEvent.tokenAmount : 0}
                  showPreview={showPreview.EVENT}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      EVENT: !prev.EVENT,
                    }))
                  }
                />
                {/* ADVISOR */}
                <CardComponent
                  타이틀="ADVISOR"
                  지갑주소={ADVISOR_ADDRESS}
                  변동량={dataAdvisor.change ? dataAdvisor : 0}
                  입금={dataAdvisor.deposit ? dataAdvisor.deposit : 0}
                  출금={dataAdvisor.withdrawal ? dataAdvisor.withdrawal : 0}
                  현재수량={
                    dataMarketing?.tokenAmount ? dataAdvisor.tokenAmount : 0
                  }
                  showPreview={showPreview.ADVISOR}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      ADVISOR: !prev.ADVISOR,
                    }))
                  }
                />
                {/* MARKETING */}
                <CardComponent
                  타이틀="MARKETING"
                  지갑주소={MARKETING_ADDRESS}
                  변동량={dataMarketing.change ? dataMarketing.change : 0}
                  입금={dataMarketing.deposit ? dataMarketing.deposit : 0}
                  출금={dataMarketing.withdrawal ? dataMarketing.withdrawal : 0}
                  현재수량={
                    dataMarketing.tokenAmount !== null ||
                    dataMarketing.tokenAmount !== undefined
                      ? dataMarketing.tokenAmount
                      : 0
                  }
                  showPreview={showPreview.MARKETING}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      MARKETING: !prev.MARKETING,
                    }))
                  }
                />
                {/* INVESTOR */}
                <CardComponent
                  타이틀="INVESTOR"
                  지갑주소={INVESTOR_ADDRESS}
                  변동량={dataInvestor.change ? dataInvestor.change : 0}
                  입금={dataInvestor.deposit ? dataInvestor.deposit : 0}
                  출금={dataInvestor.withdrawal ? dataInvestor.withdrawal : 0}
                  현재수량={
                    dataInvestor.tokenAmount ? dataInvestor.tokenAmount : 0
                  }
                  showPreview={showPreview.INVESTOR}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      INVESTOR: !prev.INVESTOR,
                    }))
                  }
                />
                {/* CARDO TO US1*/}
                <CardComponent
                  타이틀="CARDO-1"
                  지갑주소={CARDO_1_ADDRESS}
                  변동량={dataCardo_1.change ? dataCardo_1.change : 0}
                  입금={dataCardo_1.deposit ? dataCardo_1.deposit : 0}
                  출금={dataCardo_1.withdrawal ? dataCardo_1.withdrawal : 0}
                  현재수량={
                    dataCardo_1.tokenAmount ? dataCardo_1.tokenAmount : 0
                  }
                  showPreview={showPreview.CARDOTOUS1}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      CARDOTOUS1: !prev.CARDOTOUS1,
                    }))
                  }
                />
                {/* CARDO TO US2*/}
                <CardComponent
                  타이틀="CARDO-2"
                  지갑주소={CARDO_2_ADDRESS}
                  변동량={dataCardo_2.change ? dataCardo_2.change : 0}
                  입금={dataCardo_2.deposit ? dataCardo_2.deposit : 0}
                  출금={dataCardo_2.withdrawal ? dataCardo_2.withdrawal : 0}
                  현재수량={
                    dataCardo_2.tokenAmount ? dataCardo_2.tokenAmount : 0
                  }
                  showPreview={showPreview.CARDOTOUS2}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      CARDOTOUS2: !prev.CARDOTOUS2,
                    }))
                  }
                />
                {/* BITHUMB TO US*/}
                <CardComponent
                  타이틀="BITHUMB"
                  지갑주소={BITHUMB_ADDRESS}
                  변동량={dataBithumb.change ? dataBithumb.change : 0}
                  입금={dataBithumb.deposit ? dataBithumb.deposit : 0}
                  출금={dataBithumb.withdrawal ? dataBithumb.withdrawal : 0}
                  현재수량={
                    dataBithumb.tokenAmount ? dataBithumb.tokenAmount : 0
                  }
                  showPreview={showPreview.BITHUMB}
                  togglePreview={() =>
                    setShowPreview((prev) => ({
                      ...prev,
                      BITHUMB: !prev.BITHUMB,
                    }))
                  }
                />
              </div>
            )}
          </div>
        ) : (
          // Password input form
          <div>
            <h1>Enter Password to Access</h1>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              onKeyPress={handleKeyPress}
            />
            <button onClick={handlePasswordSubmit}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FoundationWallet;
