import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import TradingVolume from "./page/TradingVolume";
import FoundationWallet from "./page/FoundationWallet";
import "./App.css";
import { Header } from "./header/Header.js";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<TradingVolume />}></Route>\
          <Route
            path="/FoundationWallet"
            element={<FoundationWallet />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
